/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Segment, Image, Container } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { Link } from 'react-router-dom';
import LogoImageWhite from './Logo_white.png';
import TULogo from './TU_Dresden_Logo_weiss.svg';
import Navigation from '../Navigation/Navigation.jsx';


/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = (props) => (
  <>
    <Segment id="footer-pre">
      <Container>
        <div className='ui container twelve column grid column-grid' >
          <div className="four wide computer twelve wide mobile six wide tablet right floated column logo">
            <Image
              src={
                TULogo
              }
              alt={"Logo TU Dresden"}
            />
          </div>
          <div className="four wide computer twelve wide mobile six wide tablet right floated column logo center">
            <Image
              src={
                LogoImageWhite
              }
              alt={"Logo THS Dresden"}
            />
          </div>
          <div className="four wide computer twelve wide mobile six wide tablet right floated column ">
            <div className='ui container twelve column grid column-grid footer-pre-links' >
              <div className="four wide computer twelve wide mobile six wide tablet right floated column ">
                <Link to="/impressum">
                  Impressum
                </Link>
              </div>
              <div className="four wide computer twelve wide mobile six wide tablet right floated column ">
                <Link to="/datenschutz">
                  Datenschutz
                </Link>
              </div>

              <div className="four wide computer twelve wide mobile six wide tablet right floated column ">
                <Link to="/barrierefreiheit">
                  Barrierefreiheit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Segment>
    <Segment id="footer">
      <Container>
        <div className='ui container twelve column grid column-grid negative-margin' >
          <div className="four wide computer twelve wide mobile six wide tablet column column-blocks-wrapper wrap-overflow footer-nav">
            <Navigation pathname={"/"} onlyItems={true} />
          </div>
          {/** 
        <div className="four wide computer twelve wide mobile twelve wide tablet column column-blocks-wrapper wrap-overflow">
        </div>
        */}
          <div className="four wide computer twelve wide mobile six wide tablet right floated column column-blocks-wrapper wrap-overflow">
            <p>
              <strong>
                Unabhängige Treuhandstelle
              </strong>
            </p>
            <p>
              Adresse<br />
              Besucheradresse:<br />
              Augsburger Straße 9<br />
              01307 Dresden<br />
            </p>
            <p>
              Postadresse:<br />
              Technische Universität Dresden<br />
              Medizinische Fakultät<br />
              Unabhängige Treuhandstelle<br />
              Fetscherstr. 74<br />
              01307 Dresden<br />
              Tel. +49 351 317-7213<br />
              <strong>
                <Link to="/contact-info">treuhandstelle@tu-dresden.de</Link>
              </strong><br />
            </p>
            <p>
              <strong>
                <Link to="/login">Anmelden</Link>
              </strong>
            </p>
          </div>
        </div>
      </Container>
    </Segment>
  </>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
