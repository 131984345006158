import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import {
  flattenToAppURL,
  isInternalURL,
  withBlockExtensions,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';

export const View = ({ className, data, detached, properties, style }) => {
  const href = data?.href?.[0]?.['@id'] || '';

  const Image = config.getComponent({ name: 'Image' }).component;
  return (
    <p
      className={cx(
        {
          center: !Boolean(data.align),
          detached,
        },
        data.align,
        className,
        data.classes ? data.classes : "" + ' block image align',
      )}
      style={style}
    >
      {data.url && (
        <>
          {(() => {
            const image = (
              <Image
                className={cx({
                  'full-width': data.align === 'full',
                  large: data.size === 'l',
                  medium: data.size === 'm',
                  small: data.size === 's',

                })
                }

                src={
                  isInternalURL(data.url) ? flattenToAppURL(data.url) + "/@@images/image" : data.url
                }
                alt={data.alt || ''}
                loading="lazy"
                responsive={true}
                id={data.id ? data.id : null}
              />
            );
            if (href) {
              return (
                <UniversalLink
                  href={href}
                  openLinkInNewTab={data.openLinkInNewTab}
                >
                  {image}
                </UniversalLink>
              );
            } else {
              return image;
            }
          })()}
        </>
      )}
    </p>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
