

export const EditSchema = (intl) => ({
  title: 'Homepage Button',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text', 'url'],
    },
  ],

  properties: {
    text: {
      title: 'Text in Button',
      initialValue: 'Mehr Erfahren',
    },
    url: {
      title: 'Url Link',
      initialValue: '/',
    },
  },
  required: [],
});

