
const IScrollSchema = {
    title: "Layer",
    fieldsets: [
        {
            id: 'default',
            title: 'Default',
            fields: ['offset','duration', 'link', 'classes'],
        },
    ],
    properties: {
        offset: {
            title: "Offset",
            type: 'number',
        },
        duration: {
            title: "Duration",
            type: 'number',
        },
        link: {
            title: 'Link',
            type: 'string',
            widget: 'textarea',
        },
        classes: {
            title: 'CSS-Klassen',
            type: 'string',
            widget: 'textarea',
        },
    },
    required: [],
};
export default IScrollSchema;
