
const ILayerSchema = {
    title: "Layer",
    fieldsets: [
        {
            id: 'default',
            title: 'Default',
            fields: [],
        },
    ],
    properties: {
    },
    required: [],
};
export default ILayerSchema;
