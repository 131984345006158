import React from 'react';
import { RenderBlocks } from '@plone/volto/components';
import { Container } from 'semantic-ui-react';
import "../style/style.css"
const View = (props) => {
    const { data } = props;
    const metadata = props.metadata || props.properties;
    //const style_string = {zindex: data.z-index}
    return (
            <Container className='blockquote' >
                <RenderBlocks {...props} metadata={metadata} content={data?.data || {}} className='blockquote'/>
            </Container>
    );
};

export default View;