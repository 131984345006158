import icon from '@plone/volto/icons/send.svg'
//import BlockEdit from './Block/Edit';
//import BlockView from './Block/View';
import BlockEdit from './Edit';
import BlockView from './View';
export default function applyConfig(config) {
    config.blocks.blocksConfig.homepage_button = {
        id: 'homepage_button',
        title: 'Homepage Button',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
