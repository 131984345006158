import React from 'react';
import { ConditionalLink } from '@plone/volto/components';
import './style.css'
const View = (props) => {
    const { data, isEdit } = props;
    if (!data) {
        return <div>missing data</div>
    }
    //const style_string = {zindex: data.z-index}
    return (
        !isEdit ? (
            <ConditionalLink
                href={data.url ? data.url : "/"}
                title={data.text ? data.text : ""}
                // In case that the content returns 404, there is no information about the portal
                // then render the link anyways to get out of the Unauthorized page
                condition={!isEdit}
                className='ths-button'
            >
                {data.text ? data.text : ""}
            </ConditionalLink>
        ) : (
            <div className='ths-button'>
                {data.text ? data.text : ""}

            </div>
        )
    );
};

export default View;