import React from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang, flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const NavItem = ({ item, lang }) => {
  const { settings } = config;
  if(!item)
    return null
  // The item['@id'] in the root is ''
  // TODO: make more consistent it everywhere (eg. reducers to return '/' instead of '')
  if (isInternalURL(item['@id']) || item['@id'] === '') {
    return (
      <NavLink
        to={flattenToAppURL(item['@id'] === '' ? '/' : item['@id'])}
        key={flattenToAppURL(item['@id'])}
        className="item"
        activeClassName="active"
        exact={true}
      >
        {item.title}
      </NavLink>
    );
  } else {
    return (
      <a
        href={item['@id']}
        key={item['@id']}
        className="item"
        rel="noopener noreferrer"
        target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
      >
        {item.title}
      </a>
    );
  }
};

export default NavItem;
