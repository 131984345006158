import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Menu } from 'semantic-ui-react';

import cx from 'classnames';
import { BodyClass, getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { getNavigation } from '@plone/volto/actions';
import { CSSTransition } from 'react-transition-group';
import NavItems from '@plone/volto/components/theme/Navigation/NavItems';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

const Navigation = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname, type, onlyItems } = props;
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const lang = useSelector((state) => state.intl.locale);
  const [items, setItems] = useState();

  const cleanItems = (items) => {

    const itemsreduced = items?.items?.map((item) => {
      if (item.title == "Home") {
        return { ...item, title: "Startseite" };
      } else {
        return item;
      }
    });
    return itemsreduced;
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const fetchedItems = await dispatch(getNavigation(getBaseUrl(pathname), 5));
      setItems(cleanItems(fetchedItems)); // Update the state with the fetched items
    };

    fetchData();
  }, [pathname, token, dispatch]);


  const toggleMobileMenu = () => {
    setisMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    if (!isMobileMenuOpen) {
      return;
    }
    setisMobileMenuOpen(false);
  };

  if (onlyItems) {
    return (
      <NavItems items={items} lang={lang} />
    );
  }
  return (
    <nav className="navigation" id="navigation" aria-label="Site"
    >
      {items?.length ? (
        <div className="hamburger-wrapper ">
          <button
            className={cx('hamburger hamburger--spin', {
              'is-active': isMobileMenuOpen,
            })}
            aria-label={
              isMobileMenuOpen
                ? intl.formatMessage(messages.closeMobileMenu, {
                  type: type,
                })
                : intl.formatMessage(messages.openMobileMenu, {
                  type: type,
                })
            }
            title={
              isMobileMenuOpen
                ? intl.formatMessage(messages.closeMobileMenu, {
                  type: type,
                })
                : intl.formatMessage(messages.openMobileMenu, {
                  type: type,
                })
            }
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      ) : null}

      <CSSTransition
        in={isMobileMenuOpen}
        timeout={500}
        classNames="mobile-menu"
        unmountOnExit
      >
        <div key="mobile-menu-key" className="mobile-menu">
          <BodyClass className="has-mobile-menu-open" />
          <div className="mobile-menu-nav">
            <Menu stackable pointing secondary onClick={closeMobileMenu}>
              <NavItems items={items} lang={lang} />
            </Menu>
          </div>
        </div>
      </CSSTransition>
    </nav>
  );
};

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Navigation;
