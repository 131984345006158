import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image } from 'semantic-ui-react';
import './style.css';

const View = (props) => {
    const { data, isEdit } = props;
    if (!data) {
        return <div>missing data</div>;
    }

    return (
        <div className="columns-view">
            <div className="ui twelve column grid column-grid">
                {data.cells?.map((cell, index) => ( // Added index as the second argument if you need it
                    <div key={index} className="three wide computer twelve wide mobile six wide tablet column column-blocks-wrapper homepage-grid">
                        <div className='tile'>
                            <ConditionalLink to={flattenToAppURL(cell.link)} condition={!isEdit}>
                                <div className='tile-content'>
                                    <p>{cell.text}</p>
                                    {cell.image && <Image src={flattenToAppURL(cell.image['@id']) + '/@@images/image'} alt={cell.alt ? cell.alt : null} />}
                                </div>
                            </ConditionalLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default View;
