import React from 'react';
import { RenderBlocks } from '@plone/volto/components';
import { Container } from 'semantic-ui-react';
import "../style/style.css"
const View = (props) => {
    const { data } = props;
    const metadata = props.metadata || props.properties;
    //const style_string = {zindex: data.z-index}
    return (
        <div className='full-width grey'>
            <Container>
                <RenderBlocks {...props} metadata={metadata} content={data?.data || {}} />
            </Container>
        </div>
    );
};

export default View;