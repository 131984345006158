import icon from '@plone/volto/icons/list-bullet.svg'
import BlockEdit from './Block/EditBlock';
import BlockView from './Block/View';
export default function applyConfig(config) {
    config.blocks.blocksConfig.scroll_button = {
        id: 'scroll_button',
        title: 'Scroll_Button',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'common',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
